module.exports = {
    // 站点信息
    vendor: {
        // 基础信息
        title: "Javeray Studio", // 网页<head><title>信息

        name: "Javeray Studio",
        description: "一个SDET的云端笔记",
        introductions: ["工作了几年，看了很多博主写的文章，从中受益匪浅。通过博主们分享的技术方案，少走了很多弯路。所以自己也想做一个小站来记录和分享日常工作、生活、学习中的点点滴滴，以及一些提高效率的小工具，希望有缘的朋友们能从中得到一点点参考价值。"],

        // 备案信息
        // icp_id: null, // 不显示备案信息
        icp_id: "闽ICP备16007595号-1",

        // 加密信息
        public_key: "-----BEGIN PUBLIC KEY-----\nMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCsP6l+vrrdOSNKDYSq3LPUuKnm\n6QOI2GZPP09J5BUyr12z7WzxbHqAZVieZqhr5gKcX0J6V90po6095Qb+4DbKqyeY\nDAbyPCC5sr070ToTofFwV63JKdNLB05pOqAmyAkCqUdlwhX8TjX5dStE9Z4ZB3aI\nu2NnMdz/4ZWHDCfTbQIDAQAB\n-----END PUBLIC KEY-----",
    },

    // 技术供应商信息
    supplier: {
        // 产品名称
        capital: "Y",
        name: "log"
    }
}